<template>
    <section id='login'>
        <form class='narrowBox' @submit.prevent='proceed'>
            <h1>{{ !isSignIn ? 'כניסה למערכת' : 'אישור כתובת המייל' }}</h1>
            <div class='bg1'>
                <label>אי-מייל <input v-model='email' /></label>
            </div>
            <div class='incorrectLogin' v-if='error'>משהו השתבש. נסו שוב</div>
            <div class='linkSent' v-if='linkSent'>הקישור נשלח בהצלחה. בדקו את המייל שלכם</div>
            <button>{{ !isSignIn ? 'שלח לי קישור' : 'התחבר' }}</button>
        </form>
    </section>
</template>

<script lang='ts'>
import { defineComponent } from "@vue/runtime-core";
import { fetchSignInMethodsForEmail, getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';

export default defineComponent({
    data: () => ({ email: '', error: false, linkSent: false, isSignIn: false }),
    beforeMount(){
        if(!isSignInWithEmailLink(getAuth(), window.location.href)) return;

        this.isSignIn = true;
        this.email = window.localStorage.getItem('emailForSignIn') ?? '';
        if (this.email) this.signIn();
    },
    methods: {
        async signIn(){
            try {
                await signInWithEmailLink(getAuth(), this.email, window.location.href)
                window.localStorage.removeItem('emailForSignIn');
            }catch(error){
                console.error(error);
                this.error = true;
            }
        },
        async proceed(){
            this.error = false;
            if(this.isSignIn)
                return this.signIn();

            const signInMethods = await fetchSignInMethodsForEmail(getAuth(), this.email);
            if(!signInMethods.length) return this.error = true;

            try {
                await sendSignInLinkToEmail(getAuth(), this.email, { url: `${window.location.origin}/login`, handleCodeInApp: true });
                window.localStorage.setItem('emailForSignIn', this.email);
                this.linkSent = true;
            }catch(error){
                console.error(error);
                this.error = true;
            }
        }
    }
});
</script>

<style lang='scss'>
#login{
    margin-top: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
	input:not([type="checkbox"]) {
		min-width: 15rem;
	}
	& label:last-child{
		margin-bottom: 0;
	}

    & .incorrectLogin, & .linkSent {
        width: 100%;
        padding: 0.5rem;
        background-color: #{$swatchA}66;
        margin: 0.5rem 0;
        text-align: center;
        font-size: .9rem;
    }

    & button {
        margin: 1rem auto 1.5rem;
    }
}
</style>