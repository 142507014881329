
import { defineComponent } from "@vue/runtime-core";
import { fetchSignInMethodsForEmail, getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth';

export default defineComponent({
    data: () => ({ email: '', error: false, linkSent: false, isSignIn: false }),
    beforeMount(){
        if(!isSignInWithEmailLink(getAuth(), window.location.href)) return;

        this.isSignIn = true;
        this.email = window.localStorage.getItem('emailForSignIn') ?? '';
        if (this.email) this.signIn();
    },
    methods: {
        async signIn(){
            try {
                await signInWithEmailLink(getAuth(), this.email, window.location.href)
                window.localStorage.removeItem('emailForSignIn');
            }catch(error){
                console.error(error);
                this.error = true;
            }
        },
        async proceed(){
            this.error = false;
            if(this.isSignIn)
                return this.signIn();

            const signInMethods = await fetchSignInMethodsForEmail(getAuth(), this.email);
            if(!signInMethods.length) return this.error = true;

            try {
                await sendSignInLinkToEmail(getAuth(), this.email, { url: `${window.location.origin}/login`, handleCodeInApp: true });
                window.localStorage.setItem('emailForSignIn', this.email);
                this.linkSent = true;
            }catch(error){
                console.error(error);
                this.error = true;
            }
        }
    }
});
